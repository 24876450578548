'use client'
import { Organization } from '@/hooks/queries/useOrganizationsQuery'
import { useState, createContext, useContext } from 'react'

export const LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED =
  'LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED'

const AppContext = createContext<{
  state: { organization: Organization | null }
  actions: {
    setOrganization: (organization: Organization) => void
  }
}>({
  state: { organization: null },
  actions: { setOrganization: () => {} },
})

const useAppContext = () => {
  if (!AppContext) {
    throw new Error('useAppContext must be used within a AppProvider')
  }
  return useContext(AppContext)
}
const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [organization, setOrganization] = useState<Organization | null>(null)
  const value = {
    state: { organization },
    actions: {
      setOrganization: (organization: Organization) => {
        setOrganization(organization)
        localStorage.setItem(
          LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED,
          JSON.stringify(organization),
        )
      },
    },
  }
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export { AppContext, AppProvider, useAppContext }
