'use client'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import React, { ComponentProps, PropsWithChildren } from 'react'
import classNames from 'classnames'

interface NavLinkProps extends ComponentProps<typeof Link> {
  href: string
  className?: string
  activeClasses: string
  onClick?: any
  active?: boolean
  newTab?: boolean
  disabled?: boolean
  menuItem?: boolean
}

const NavLink = ({
  children,
  active,
  href,
  className,
  activeClasses,
  onClick,
  newTab,
  disabled,
  menuItem,
  ...rest
}: PropsWithChildren<NavLinkProps>) => {
  const pathname = usePathname()
  const isActive = pathname === href ? true : active
  return (
    <Link
      aria-disabled={disabled}
      target={newTab ? '_blank' : ''}
      rel={newTab ? 'noopener' : ''}
      onClick={onClick}
      href={disabled ? '' : href}
      className={classNames(
        'Milestone-link',
        {
          'hover:bg-neutral-10': menuItem && !disabled,
          'disabled pointer-events-none !text-light-grey': disabled,
          'visited:text-inherit': !isActive,
          [activeClasses]: isActive,
          active: isActive,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Link>
  )
}

export default NavLink
