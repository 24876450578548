'use client'
import { LOCAL_STORAGE_SIDEBAR_STATE } from '@/constants'
import NavLink from '@/components/NavLink'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import { Button, Tooltip } from '@/lib/mui'
import { KeyboardTab, KeyboardArrowLeft } from '@mui/icons-material'
import { setCookie } from 'cookies-next'
import Link from 'next/link'
import classNames from 'classnames'

export interface DashboardLink {
  newTab?: boolean
  title: string
  href: string
  Icon?: any
  disabled?: boolean
  groupId?: string
}

export interface SidebarProps {
  state: boolean
  logoComp: React.ReactNode
  logoHref: string
  showBackButton?: boolean
}

const SIDEBAR_CONTEXT_INIT_STATE = {
  sidebarOpen: true as boolean,
  handleSidebarOpen: () => {},
}

const SidebarContext = createContext(SIDEBAR_CONTEXT_INIT_STATE)

export const useSidebarContext = () => {
  if (!SidebarContext) {
    throw new Error('useSidebarContext must be used within SidebarContext')
  }
  return useContext(SidebarContext)
}

export const SidebarContextProvider: FC<
  PropsWithChildren<{ sidebarOpenInitState: boolean }>
> = ({ children, sidebarOpenInitState }) => {
  const [sidebarOpen, setSidebarOpen] = useState(sidebarOpenInitState)
  const handleSidebarOpen = () => {
    setSidebarOpen((prev) => {
      setCookie(LOCAL_STORAGE_SIDEBAR_STATE, !prev, { sameSite: true })
      return !prev
    })
  }
  return (
    <SidebarContext.Provider value={{ sidebarOpen, handleSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  )
}

const SidebarTemplate: FC<PropsWithChildren<Omit<SidebarProps, 'state'>>> = ({
  children,
  logoComp,
  showBackButton,
  logoHref,
}) => {
  const { sidebarOpen, handleSidebarOpen } = useSidebarContext()
  return (
    <aside
      className={classNames(
        'sticky top-0 hidden h-fit min-h-screen transform-gpu flex-col content-start gap-6 bg-white px-5 pb-10 transition-[width] duration-300 ease-in-out lg:flex',
        {
          'w-[220px]': sidebarOpen,
          'w-[95px]': !sidebarOpen,
          'pt-[52px]': !showBackButton,
          'pt-4': showBackButton,
        },
      )}
    >
      <div className="flex select-none flex-col gap-1">
        {/* {showBackButton && <BackButton className="self-start" />} */}
        {showBackButton && (
          <Link href="/dashboard">
            <Button
              color="secondary"
              variant="text"
              className="self-start"
              startIcon={<KeyboardArrowLeft />}
            >
              {sidebarOpen ? 'Back' : null}
            </Button>
          </Link>
        )}
        <Link href={logoHref}>{logoComp}</Link>
      </div>
      <div className={classNames('flex flex-1 flex-col gap-6')}>{children}</div>

      <div className="">
        <Tooltip title={sidebarOpen ? 'Collapse' : 'Expand'}>
          <Button
            color="info"
            className="text-small h-[32px] w-full"
            size="small"
            variant="outlined"
            startIcon={
              <KeyboardTab
                className={classNames({ 'rotate-180': sidebarOpen })}
              />
            }
            sx={{
              '.MuiButton-icon': {
                marginRight: sidebarOpen ? '8px' : '0',
              },
            }}
            onClick={handleSidebarOpen}
          >
            {sidebarOpen && 'Collapse'}
          </Button>
        </Tooltip>
      </div>
    </aside>
  )
}

export default SidebarTemplate

export const DashboardNavLink = ({
  link,
  activeClasses,
  className,
  onClick,
}: {
  link: DashboardLink
  activeClasses?: string
  className?: string
  onClick?: () => void
}) => {
  const { Icon } = link
  const { sidebarOpen } = useSidebarContext()
  return (
    <NavLink
      disabled={link.disabled}
      newTab={link.newTab}
      href={link.href}
      className={classNames(
        'grid w-fit items-center overflow-hidden px-2.5 py-1.5 text-md text-black transition-[grid-template-columns] ease-linear hover:!rounded-full hover:text-blue-900',
        {
          'grid-cols-[16px,_1fr] gap-2': sidebarOpen,
          'grid-cols-[1fr,_0px]': !sidebarOpen,
        },
        className,
      )}
      activeClasses={classNames('font-bold text-blue-800', activeClasses)}
      menuItem={true}
      onClick={onClick}
    >
      {!!Icon && (
        <div
          className={classNames('flex h-5 w-5 items-center justify-center', {
            'text-light-grey': link.disabled,
          })}
        >
          {Icon}
        </div>
      )}
      <div className="flex overflow-hidden">
        <span className={'min-w-max font-semibold'}>{link.title}</span>
      </div>
    </NavLink>
  )
}
