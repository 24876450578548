import React, { FC, useState } from 'react'
import { AVATAR_NAVBAR_LINKS } from '@/constants'
import classNames from 'classnames'
import { DashboardNavLink } from '@/components/dashboard/sidebar/SidebarTemplate'
import { ExpandMore } from '@mui/icons-material'

export const NavbarLinksSection: FC<{
  visible: boolean
  onClick: () => void
}> = ({ visible, onClick }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  if (!visible) return null
  return (
    <div
      className={classNames(
        'grid gap-3 overflow-hidden transition-[grid-template-rows] ease-linear',
        { 'grid-rows-[min-content,_1fr]': isExpanded },
        { 'grid-rows-[min-content,_0fr]': !isExpanded },
      )}
    >
      <div
        className="flex cursor-pointer items-center"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <span className="font-bold text-neutral-600">Settings</span>
        <ExpandMore className={classNames({ 'rotate-180': isExpanded })} />
      </div>
      <div className="flex flex-col gap-3 overflow-hidden">
        {AVATAR_NAVBAR_LINKS.filter(({ disabled }) => !disabled).map(
          (link, index) => (
            <DashboardNavLink link={link} key={index} onClick={onClick} />
          ),
        )}
      </div>
    </div>
  )
}
