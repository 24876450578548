import React, { FC, PropsWithChildren, useState } from 'react'
import { Button, Drawer, IconButton } from '@/lib/mui'
import MenuIcon from '@mui/icons-material/Menu'
import AdminButton from '@/components/Header/SideMenu/components/AdminButton'
import { usePathname } from 'next/navigation'
import Image from 'next/image'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import Logo from '@public/assets/logo-blue.svg'
import classNames from 'classnames'
import { Logout } from '@mui/icons-material'
import { DASHBOARDS_ROUTES, internalNav } from '@/constants'
import {
  NavbarLinksSection,
  ServicesSection,
  UnsignedNavigationSection,
  DashboardLinksSection,
  HelperLinksSection,
  UserAvatarSection,
} from './components'
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated'
interface Props {
  logo?: React.ReactNode
}

const MobileMenu: FC<PropsWithChildren<Props>> = ({ logo, children }) => {
  const session = useSession()
  const isAuthenticated = useIsAuthenticated()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const pathname = usePathname()

  const signOutEvent = () => {
    signOut({ callbackUrl: '/' })
  }

  const handleClose = () => {
    setIsDrawerOpen(false)
    // if (href) handle(event, href)
  }
  const shouldRenderLogo = DASHBOARDS_ROUTES.some((route) =>
    pathname.includes(route),
  )
  return (
    <>
      <div className="flex w-full items-center justify-between lg:hidden">
        {shouldRenderLogo ? (
          <Image quality={80} width={100} src={Logo} alt="" />
        ) : (
          <div></div>
        )}
        <IconButton
          size="small"
          color="inherit"
          onClick={() => setIsDrawerOpen(true)}
          data-cy="mobile-menu-button"
        >
          <MenuIcon />
        </IconButton>
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        className="over"
        PaperProps={{
          className: 'overflow-y-auto',
        }}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div className="relative flex min-w-[240px] flex-1 flex-col gap-4 p-5">
          {session?.data?.user.profile === 'admin' && (
            <div className="px-5">
              <AdminButton />
            </div>
          )}
          {logo != null ? (
            <div onClick={handleClose} className="">
              {logo}
            </div>
          ) : (
            <div className="flex justify-center rounded-xl bg-blue-50 px-3 py-4">
              <Image quality={24} width={140} src={Logo} alt="Milestone Logo" />
            </div>
          )}

          <UserAvatarSection />
          {children != null ? (
            children
          ) : (
            <>
              <DashboardLinksSection
                visible={isAuthenticated}
                onClick={handleClose}
              />
              <NavbarLinksSection
                visible={isAuthenticated}
                onClick={handleClose}
              />
              <UnsignedNavigationSection
                visible={!isAuthenticated}
                onClick={handleClose}
              />
              <ServicesSection onClick={handleClose} />
              <HelperLinksSection
                visible={isAuthenticated}
                onClick={handleClose}
              />
            </>
          )}

          <div
            className={classNames('flex flex-col gap-4', {
              'mt-auto': !isAuthenticated || !!children,
            })}
          >
            {isAuthenticated ? (
              <Button
                onClick={signOutEvent}
                variant="text"
                endIcon={<Logout />}
              >
                Sign out
              </Button>
            ) : (
              <>
                <Link href={internalNav.auth.signin}>
                  <Button className="w-full" variant="outlined" color="info">
                    Sign in
                  </Button>
                </Link>
                <Link href={internalNav.auth.register}>
                  <Button className="w-full">Register</Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default MobileMenu
