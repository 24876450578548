import React, { FC } from 'react'
import { DASHBOARD_LINKS_HELP_WIDGET } from '@/constants'
import { DashboardNavLink } from '@/components/dashboard/sidebar/SidebarTemplate'

export const HelperLinksSection: FC<{
  visible: boolean
  onClick: () => void
}> = ({ visible, onClick }) => {
  if (!visible) return null

  return (
    <div className="mt-auto flex flex-col gap-3 rounded-md bg-blue-600-opacity-005 p-3">
      {DASHBOARD_LINKS_HELP_WIDGET.filter(({ disabled }) => !disabled).map(
        (link, index) => (
          <DashboardNavLink link={link} key={index} onClick={onClick} />
        ),
      )}
    </div>
  )
}
