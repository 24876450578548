import Image from 'next/image'
import ethicalBadge from '@public/assets/illustrations/small/ethical_badge_mini.svg'
import { Avatar, IconButton } from '@mui/material'
import { stringAvatar } from '@/lib/utils'
import useCurrentUserQuery from '@/hooks/queries/useCurrentUserQuery'
import { Session } from 'next-auth'

/**
 * @returns
 * A string or image avatar, based on image existence for the logged in user
 * Displays an ethical oath badge in case the ethical oath is signed
 */
const AvatarAccount = ({
  session,
  handleClick,
  open,
}: {
  session: Session | null
  handleClick?: any
  open?: boolean
}) => {
  const { data: user } = useCurrentUserQuery({})

  return (
    <div className="relative">
      {user && user.is_oath_accepted && (
        <Image
          className="absolute -right-2 -top-0.5 z-20"
          src={ethicalBadge}
          alt="ethical badge"
        />
      )}
      <IconButton
        data-cy="navbar-account-menu-button"
        onClick={handleClick}
        size="small"
        sx={{ p: 0 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {session && session.user && session.user.image ? (
          <Avatar
            sx={{ width: 30, height: 30 }}
            src={session.user.image}
            alt="alt"
          />
        ) : (
          <Avatar
            sx={{
              width: 30,
              height: 30,
              bgcolor: 'secondary.main',
              fontSize: '12px',
            }}
            {...stringAvatar(
              session?.user?.name as string,
              session?.user?.familyName as string,
            )}
          />
        )}
      </IconButton>
    </div>
  )
}
export default AvatarAccount
