'use client'
import React, { useState, useContext } from 'react'
import { Avatar, Menu, MenuItem } from '@mui/material'
import { colors } from 'tailwind.config'
import useOrganizationsQuery, {
  Organization,
} from '@/hooks/queries/useOrganizationsQuery'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { AppContext } from '@/components/Providers/AppProvider'
import CorporateFareIcon from '@public/assets/icon_organization.svg'
import Image from 'next/image'

export default function OrganizationSelector() {
  const { state, actions } = useContext(AppContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { data: organizations } = useOrganizationsQuery({})
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionSelect = (option: Organization) => {
    actions.setOrganization(option)
    handleClose()
  }

  return (
    <div className="relative">
      <div
        className="flex cursor-pointer items-center space-x-2 rounded-full bg-gray-100 px-4 py-2 hover:bg-neutral-200"
        onClick={handleClick}
      >
        <Avatar
          className="h-7 w-7"
          sx={{
            width: 30,
            height: 30,
            bgcolor: colors.neutral[700],
            fontSize: '12px',
          }}
        >
          <Image
            src={CorporateFareIcon}
            className="w-[16px]"
            width={16}
            height={16}
            alt="corporate logo"
          />
        </Avatar>
        <span className="text w-fit max-w-[230px] truncate font-medium text-gray-700">
          {state.organization?.name}
        </span>
        <ArrowDropDownIcon className="h-4 w-4 text-gray-500" />
      </div>
      {organizations && organizations.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {organizations?.map((option) => (
            <MenuItem
              key={option.id}
              onClick={() => handleOptionSelect(option)}
              selected={option.id === state.organization?.id}
              className="px-4 py-2"
            >
              <div className="flex items-center space-x-3">
                <Avatar className="h-7 w-7 bg-gray-700">
                  <Image
                    src={CorporateFareIcon}
                    className="w-[16px]"
                    width={16}
                    height={16}
                    alt="corporate logo"
                  />
                </Avatar>
                <span className="w-48 truncate">{option.name}</span>
              </div>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  )
}
