'use client'
import React, { useContext, useEffect } from 'react'
import DesktopMenu from '@/components/Header/SideMenu/DesktopMenu'
import MobileMenu from '@/components/Header/SideMenu/MobileMenu'
import { HeaderProps } from '@/components/Header/Header'
import {
  AppContext,
  LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED,
} from '@/components/Providers/AppProvider'
import useOrganizationsQuery, {
  Organization,
} from '@/hooks/queries/useOrganizationsQuery'
import { redirect, usePathname } from 'next/navigation'
import classNames from 'classnames'

const SideMenu = ({
  theme,
  className,
  sideMenuContent,
  sideMenuLogo,
}: HeaderProps) => {
  const { actions } = useContext(AppContext)

  const {
    data: organizations,
    isFetching,
    isPending,
  } = useOrganizationsQuery({})
  const pathname = usePathname()

  useEffect(() => {
    const ls_organization = localStorage.getItem(
      LOCAL_STORAGE_KEY_ORGANIZATION_SELECTED,
    )
    if (organizations && organizations.length > 0) {
      if (ls_organization && ls_organization !== 'undefined') {
        const organization = JSON.parse(ls_organization)
        const foundOrganization = organization
          ? organizations?.find((w: Organization) => w.id === organization.id)
          : null
        foundOrganization
          ? actions.setOrganization(foundOrganization)
          : actions.setOrganization(organizations[0])
      } else {
        actions.setOrganization(organizations[0])
      }
    }
  }, [organizations, actions])

  useEffect(() => {
    const userDoneOnboarding = organizations != null && organizations.length > 0
    const isOnboarding = pathname === '/on-boarding'
    if (userDoneOnboarding || isOnboarding || isFetching || isPending) return

    redirect('/on-boarding')
  }, [pathname, organizations, isFetching, isPending])

  return (
    <>
      <div
        className={classNames(
          'hidden flex-1 justify-end gap-2 lg:flex lg:gap-4',
          className,
        )}
      >
        <DesktopMenu theme={theme} />
      </div>
      <MobileMenu logo={sideMenuLogo}>{sideMenuContent}</MobileMenu>
    </>
  )
}

export default SideMenu
