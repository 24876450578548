import React, { FC } from 'react'
import { DASHBOARD_LINKS } from '@/constants'
import { DashboardNavLink } from '@/components/dashboard/sidebar/SidebarTemplate'

export const DashboardLinksSection: FC<{
  visible: boolean
  onClick: () => void
}> = ({ visible, onClick }) => {
  if (!visible) return null

  return (
    <div className="flex flex-col gap-3">
      {DASHBOARD_LINKS.filter(({ disabled }) => !disabled).map((link) => (
        <DashboardNavLink link={link} key={link.title} onClick={onClick} />
      ))}
    </div>
  )
}
