import { useContext, useState } from 'react'
import Image from 'next/image'
import SignOutIcon from '@public/assets/icons/sign_out.svg'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { Avatar, Menu, MenuItem, Divider, IconButton } from '@/lib/mui'
import { AVATAR_NAVBAR_LINKS } from '@/constants'
import { stringAvatar } from '@/lib/utils'
import AdminButton from '@/components/Header/SideMenu/components/AdminButton'
import QuickNavigation from '@/components/Header/SideMenu/components/QuickNavigation'
import AvatarAccount from '@/components/Header/SideMenu/components/AvatarAccount'
import OrganizationSelector from '@/components/Header/SideMenu/components/OrganizationSelector'
import { AppContext } from '@/components/Providers/AppProvider'
import { DashboardLink } from '@/components/dashboard/sidebar/SidebarTemplate'
import classNames from 'classnames'
import styles from './DesktopMenu.module.css'
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated'
export default function DesktopMenu({
  theme,
}: {
  theme?: 'light' | 'dark' | 'dashboard'
}) {
  const session = useSession()
  const isAuthenticated = useIsAuthenticated()
  const { state } = useContext(AppContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const accountMenuProps = {
    paper: {
      elevation: 0,
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: 'primary.dark',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      },
    },
  }
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event?: any, href?: string) => {
    setAnchorEl(null)
  }
  const signOutEvent = () => {
    setAnchorEl(null)
    signOut({ callbackUrl: '/' })
  }

  return (
    /* 
      magic width number to preserve the position
      of the other elements on the Header component
    */
    <div className="flex min-w-[210px] items-center justify-between gap-2.5">
      <QuickNavigation theme={theme} />
      {theme === 'dashboard' && <OrganizationSelector />}
      {/* <Notifications theme={theme} /> */}
      {isAuthenticated && (
        <AvatarAccount
          handleClick={handleClick}
          session={session.data}
          open={open}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => handleClose()}
        onClick={() => handleClose()}
        slotProps={accountMenuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="-mt-2 mb-2 p-0">
          <div className="w-full">
            <div className="h-[57px] rounded-t-md bg-blue-800 p-0"></div>
            <div className="-mt-12 flex flex-col items-center p-4">
              {session && session.data?.user && session.data?.user.image ? (
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  src={session.data?.user.image}
                  alt="alt"
                />
              ) : (
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    bgcolor: 'secondary.main',
                    fontSize: '12px',
                  }}
                  {...stringAvatar(
                    session?.data?.user?.name as string,
                    session?.data?.user?.familyName as string,
                  )}
                />
              )}
              <span className="mt-2 text-sm font-bold">
                {session?.data?.user?.name} {session?.data?.user?.familyName}
              </span>
              <span className="mt-2 text-sm text-neutral-600">
                {state.organization?.name}
              </span>
            </div>
          </div>
        </div>
        <Divider variant="middle" />
        {session?.data?.user.profile === 'admin' && (
          <div className="mb-5 mt-2.5 px-5">
            <AdminButton />
          </div>
        )}
        {session?.data?.user.profile === 'admin' && (
          <Divider variant="middle" />
        )}

        {AVATAR_NAVBAR_LINKS.filter(({ disabled }) => !disabled).map((link) => (
          <MenuItem
            data-cy="navbar-account-menu-item"
            key={link.title}
            component={Link}
            href={link.href}
            target={link.newTab ? '_blank' : ''}
            onClick={(event) => handleClose(event, link.href)}
            className="flex gap-2 text-md"
          >
            <LinkContent {...link} />
          </MenuItem>
        ))}

        <Divider variant="middle" />

        <MenuItem onClick={signOutEvent} className="flex gap-2 text-md">
          <Image src={SignOutIcon} alt="sign out" />
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  )
}

const LinkContent = ({ Icon, title }: DashboardLink) => (
  <>
    <div
      className={classNames(
        styles['nav-link-icon'],
        'flex items-center justify-center',
      )}
    >
      {Icon}
    </div>
    <div className="flex overflow-hidden">
      <span className={'min-w-max font-semibold'}>{title}</span>
    </div>
  </>
)
