import { DASHBOARDS_ROUTES, internalNav } from '@/constants'
import { HeaderProps } from '@/components/Header/Header'
import { ArrowOutward } from '@mui/icons-material'
import { Button } from '@mui/material'
import classNames from 'classnames'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated'

/**
 * @returns
 * 'Go to dashboard button on all pages outside dashboard area'
 * 'Train Model' on all pages inside dashboard area
 */
const QuickNavigation = ({ theme }: Pick<HeaderProps, 'theme'>) => {
  const session = useSession()
  const pathname = usePathname()
  const isAuthenticated = useIsAuthenticated()
  const shouldRender = DASHBOARDS_ROUTES.every(
    (route) => !pathname.includes(route),
  )

  if (!shouldRender) return null
  if (session.status === 'loading') return null
  if (!isAuthenticated)
    return (
      <div className="flex items-center gap-3">
        <Link href={internalNav.auth.signin}>
          <Button
            size="small"
            variant="outlined"
            color={theme === 'dark' ? 'inherit' : 'info'}
          >
            Sign in
          </Button>
        </Link>
        <Link href={internalNav.auth.register}>
          <Button
            size="small"
            color={theme === 'dark' ? 'info' : 'primary'}
            variant="contained"
            endIcon={<ArrowOutward />}
            className={classNames({ 'bg-white text-black': theme === 'dark' })}
          >
            Register
          </Button>
        </Link>
      </div>
    )

  return (
    <Link href="/dashboard">
      <Button
        size="small"
        endIcon={<ArrowOutward />}
        className={classNames({ 'bg-white text-black': theme === 'dark' })}
        color={theme === 'dark' ? 'info' : 'primary'}
      >
        Go to dashboard
      </Button>
    </Link>
  )
}
export default QuickNavigation
