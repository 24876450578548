import React, { FC, useState } from 'react'
import { SERVICES_HREFS, TRAINING_AAS_DASHBOARD_HREFS } from '@/constants'
import Image from 'next/image'
import classNames from 'classnames'
import { DashboardNavLink } from '@/components/dashboard/sidebar/SidebarTemplate'
import { ExpandMore } from '@mui/icons-material'
import TrainingAASIcon from '@public/assets/illustrations/logos/small/training_aas.svg'
import VlmAASIcon from '@public/assets/illustrations/logos/small/vlm_aas.svg'

export const ServicesSection: FC<{
  onClick: () => void
}> = ({ onClick }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  return (
    <div
      className={classNames(
        'grid gap-3 overflow-hidden transition-[grid-template-rows] ease-linear',
        { 'grid-rows-[min-content,_1fr]': isExpanded },
        { 'grid-rows-[min-content,_0fr]': !isExpanded },
      )}
    >
      <div
        className="flex cursor-pointer items-center"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <span className="font-bold text-neutral-600">Services</span>
        <ExpandMore className={classNames({ 'rotate-180': isExpanded })} />
      </div>
      <div className="flex flex-col gap-3 overflow-hidden">
        <DashboardNavLink
          link={{
            href: TRAINING_AAS_DASHBOARD_HREFS.EXPERIMENTS,
            title: 'Training-aaS',
            Icon: <Image src={TrainingAASIcon} alt="Training AAS Icon" />,
          }}
          onClick={onClick}
          className="hover:!bg-green-50 hover:!text-black"
        />
        <DashboardNavLink
          link={{
            href: SERVICES_HREFS.VLM_AAS,
            title: 'VLM-aaS',
            Icon: <Image src={VlmAASIcon} alt="VLM AAS Icon" />,
          }}
          onClick={onClick}
          className="hover:!bg-orange-50 hover:!text-black"
        />
      </div>
    </div>
  )
}
