import React, { FC } from 'react'
import { SUPPORT } from '@/constants'
import { DashboardNavLink } from '@/components/dashboard/sidebar/SidebarTemplate'

export const UnsignedNavigationSection: FC<{
  visible: boolean
  onClick: () => void
}> = ({ visible, onClick }) => {
  if (!visible) return null

  return (
    <div className="flex flex-col gap-3">
      <DashboardNavLink
        link={{
          href: '/data-library',
          title: 'Datasets',
        }}
        onClick={onClick}
      />
      <DashboardNavLink
        link={{
          href: '/ethics',
          title: 'Ethics',
        }}
        onClick={onClick}
      />
      <DashboardNavLink
        link={{
          href: SUPPORT.docs,
          title: 'Documentation',
          newTab: true,
        }}
        onClick={onClick}
      />
    </div>
  )
}
