import React from 'react'
import { Avatar } from '@/lib/mui'
import { stringAvatar } from '@/lib/utils'
import { useSession } from 'next-auth/react'
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated'

export const UserAvatarSection = () => {
  const session = useSession()
  const isAuthenticated = useIsAuthenticated()
  if (!isAuthenticated || !session.data) return null

  const {
    data: { user },
  } = session
  return (
    <div className="flex items-center gap-3">
      {user && user.image ? (
        <Avatar sx={{ width: 32, height: 32 }} src={user.image} alt="alt" />
      ) : (
        <Avatar
          sx={{
            width: 32,
            height: 32,
            bgcolor: 'secondary.main',
            fontSize: '12px',
          }}
          {...stringAvatar(user?.name as string, user?.familyName as string)}
        />
      )}
      <span className="text-sm font-bold">
        {user?.name} {user?.familyName}
      </span>
      {/* <span className="mt-2 text-sm text-neutral-600">
              {state.organization?.name}
            </span> */}
    </div>
  )
}
