import { ApiClient } from '@/lib/mdip/api-client'
import { ApiResponseType } from '@/lib/mdip/types'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'

const url = '/api/v1/organizations' as const
type OrganizationList = ApiResponseType<typeof url, 'get'>
export type Organization = OrganizationList[0]

type UseDatasetsQueryProps = {
  config?: Omit<
    UseQueryOptions<OrganizationList, Error, OrganizationList>,
    'queryKey'
  >
}

export const USER_ORGANIZATIONS_QUERY_KEY = 'organizations'

export default function useOrganizationsQuery({
  config,
}: UseDatasetsQueryProps) {
  const { data: session } = useSession()
  return useQuery({
    enabled: !!session?.token,
    gcTime: 1000 * 60,
    queryKey: [USER_ORGANIZATIONS_QUERY_KEY],
    queryFn: async () =>
      ApiClient({
        url,
        method: 'get',
        options: { cache: 'no-store' },
      }),
    ...config,
  })
}
