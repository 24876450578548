import { ArrowOutward } from '@mui/icons-material'
import { Button } from '@mui/material'
import Link from 'next/link'

/**
 * @returns
 * Display a go to admin button
 */
const AdminButton = () => {
  return (
    <Link href="/admin">
      <Button size="small" className="w-full" endIcon={<ArrowOutward />}>
        Go to Admin
      </Button>
    </Link>
  )
}
export default AdminButton
